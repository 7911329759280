<template>
    <div v-if="transportSettings && transportSettingsData">
        <mercur-card
            class="full-height-layout fill"
            v-if="hasPermission"
        >
            <h2 class="font-weight-normal">Edit transport settings</h2>
            <p class="mt-0" v-if="transportSettingsData.supplierName">Supplier: {{transportSettingsData.supplierName}}</p>
            <p class="mt-0" v-if="transportSettingsData.locationName">Facility: {{transportSettingsData.locationName}}</p>

            <div class="full-height-layout fill">
                <div class="d-flex">
                    <div class="col col-6">
                        <div>
                            <pretty-select
                                placeholder="Supplier"
                                label="supplierName"
                                :options="allSuppliers"
                                v-model="$v.transportSettingsData.supplierId.$model"
                                :reduce="supplier => supplier.supplierId"
                                ref="supplierSelection"
                                v-if="formType === 'new' && allSuppliers"
                            />
                            <span class="error pretty-select-error" v-if="$v.transportSettingsData.supplierId.$error">
                                Supplier field is required
                            </span>
                        </div>
                        <div v-if="allLocations">
                            <pretty-select
                                placeholder="Facility"
                                label="label"
                                :options="allLocations"
                                v-model="$v.transportSettingsData.facilityIds.$model"
                                :reduce="location => location.locationId"
                                multiple
                            />
                        </div>
                        <fieldset class="py-3 mt-3">
                            <legend>Status</legend>
                            <mercur-select v-model="$v.transportSettingsData.settingsStatus.$model">
                                <option
                                    v-for="status in settingStatusses"
                                    :key="status.value"
                                    :value="status.value"
                                >{{status.title}} - {{status.value}}</option>
                            </mercur-select>
                        </fieldset>
                        <fieldset class="py-3 mt-3">
                            <legend>Callbacks</legend>
                            <mercur-checkbox v-model="$v.transportSettingsData.transportSettings.willSubmitCallbacks.$model">Will submit callbacks</mercur-checkbox>
                            <div v-if="transportSettingsData.transportSettings.willSubmitCallbacks" class="mt-3">
                                Callback Class:
                                <mercur-select v-model="callbackClassHelper">
                                    <option value="Mercur">Mercur</option>
                                    <option :value="null">Custom (please define)</option>
                                </mercur-select>
                                <div v-if="transportSettingsData.callbackSettings.callbackClass !== defaultMercurCallbackClass">
                                    <mercur-input
                                        v-model="$v.transportSettingsData.callbackSettings.callbackClass.$model"
                                    >
                                        Custom class
                                        <template slot="note" v-if="$v.transportSettingsData.callbackSettings.callbackClass.$error">
                                            <span class="error" v-if="!$v.transportSettingsData.callbackSettings.callbackClass.required">Callback class is required</span>
                                        </template>
                                    </mercur-input>
                                </div>
                                <div v-if="callbackClassHelper === null">
                                   <mercur-textarea v-model="$v.transportSettingsData.callbackSettings.callbackSettings.$model">
                                        Callback settings
                                            <template slot="note" v-if="$v.transportSettingsData.callbackSettings.callbackSettings.$error">
                                                <span class="error" v-if="!$v.transportSettingsData.callbackSettings.callbackSettings.isJson">Must be formatted as Json</span>
                                            </template>
                                    </mercur-textarea>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col col-6">
                        <mercur-select v-model="$v.transportSettingsData.transportSettings.transportClass.$model">
                            <template #label>Form of transport</template>
                            <option
                                v-for="formOfTransport in formsOfTransport"
                                :key="formOfTransport.value"
                                :value="formOfTransport.value"
                            >{{formOfTransport.title}}</option>
                        </mercur-select>

                        <div class="row">
                            <div v-for="field in transportFields" :key="field" class="col" :class="['transportFormat', 'additionalData'].includes(field) ? 'col-12' : 'col-6'">
                                <label>{{getFieldName(field)}}</label>
                                <template v-if="field === 'transportFormat'">
                                    <mercur-select v-model="$v.transportSettingsData.transportSettings[field].$model" :placeholder="isCustomFormat ? 'Custom:' : ''">
                                        <option v-for="format in formats" :key="format.value" :value="format.value">{{format.title}}</option>
                                        <option value="Custom\ClassName">Custom:</option>
                                    </mercur-select>
                                    <mercur-input v-if="isCustomFormat" ref="customTransportFormat" v-model="$v.transportSettingsData.transportSettings[field].$model"></mercur-input>
                                </template>
                                <template v-else-if="field === 'additionalData'">
                                    <mercur-textarea v-model="$v.transportSettingsData.transportSettings.additionalData.$model">
                                        <template slot="note" v-if="$v.transportSettingsData.transportSettings.additionalData.$error">
                                            <span class="error" v-if="!$v.transportSettingsData.transportSettings.additionalData.isJson">Must be formatted as Json</span>
                                        </template>
                                    </mercur-textarea>
                                </template>
                                <template v-else>
                                    <mercur-input v-model="$v.transportSettingsData.transportSettings[field].$model"></mercur-input>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-3">
                <mercur-button class="btn btn-yellow btn-raised text-uppercase" :disabled="loading" v-if="(formType === 'new' && this.isAllowedTo('addSupplierTransportSetting')) || (formType !== 'new' && this.isAllowedTo('updateSupplierTransportSetting'))" @click="submit">Save</mercur-button>
            </div>
        </mercur-card>
        <p v-else class="permission-message">Not allowed to see this view</p>
    </div>
</template>

<script>
import CONFIG from '@root/config'
import { validationMixin } from 'vuelidate'
import {
    required,
    requiredIf,
} from 'vuelidate/lib/validators'
import PrettySelect from '../../components/utils/PrettySelect'
const isJson = (value) => { try { JSON.parse(value) } catch (e) { return false } return true }
export default {
    name: 'OrderSubmissionsTransportSettingsEdit',
    components: { PrettySelect },
    mixins: [ validationMixin ],
    props: {
        transportSettings: {},
    },
    data () {
        return {
            loading: false,
            allSuppliers: null,
            allLocations: null,
            formsOfTransport: CONFIG.TRANSPORT_SETTINGS.FORMS_OF_TRANSPORT,
            formats: CONFIG.TRANSPORT_SETTINGS.FORMATS,
            settingStatusses: CONFIG.TRANSPORT_SETTINGS.SETTING_STATUSSES,
            defaultMercurCallbackClass: CONFIG.TRANSPORT_SETTINGS.CALLBACKS.DEFAULT_MERCUR_CALLBACK_CLASS,
            transportSettingsData: {},
        }
    },
    computed: {
        callbackClassHelper: {
            get () {
                return this.transportSettings.callbackSettings.callbackClass === this.defaultMercurCallbackClass ? 'Mercur' : null
            },
            set (value) {
                this.$set(this.transportSettings.callbackSettings, 'callbackClass', value === 'Mercur' ? this.defaultMercurCallbackClass : null)
            },
        },
        hasPermission () {
            if (this.formType === 'new' && this.isAllowedTo('addSupplierTransportSetting')) {
                return true
            }
            if (this.formType !== 'new' && this.isAllowedTo('updateSupplierTransportSetting')) {
                return true
            }

            return false
        },
        isCustomFormat () {
            if (!this.$v.transportSettingsData.transportSettings.transportFormat) {
                return false
            }

            const value = this.$v.transportSettingsData.transportSettings.transportFormat.$model
            const staticFormats = this.formats.map(format => format.value)

            if (staticFormats.includes(value)) {
                return false
            }

            return true
        },
        transportClass () {
            return this.transportSettingsData.transportSettings.transportClass
        },
        transportFields () {
            const formOfTransportDefinition = this.formsOfTransport.find(({ value }) => value === this.transportSettingsData.transportSettings.transportClass)

            if (formOfTransportDefinition && formOfTransportDefinition.fields) {
                return formOfTransportDefinition.fields
            }
            return []
        },
        transportFieldValidations () {
            return this.transportFields.reduce((accumulator, currentValue) => {
                accumulator[currentValue] = {
                    required,
                    isJson,
                }
                return accumulator
            }, {})
        },
        formType () {
            return this.$route.meta.type === 'new' ? 'new' : 'edit'
        },
    },
    validations () {
        return {
            transportSettingsData: {
                settingsStatus: {
                    valid: v => ['COMPLETE', 'DRAFT', 'INACTIVE'].includes(v),
                },
                supplierId: {},
                facilityIds: {},
                transportSettings: {
                    transportClass: {},
                    transportFormat: {
                        required,
                    },
                    willSubmitCallbacks: {},
                    additionalData: {},
                    ...this.transportFieldValidations,
                },
                callbackSettings: {
                    callbackClass: {
                        required: requiredIf(() => this.transportSettingsData.transportSettings.willSubmitCallbacks),
                    },
                    callbackSettings: {
                        isJson,
                    },
                },
            },
        }
    },
    watch: {
        transportSettings: {
            handler (value) {
                if (value) {
                    this.transportSettingsData = JSON.parse(JSON.stringify(value))
                    if (typeof value.transportSettings.additionalData === 'object') {
                        const additionalDataStringify = JSON.stringify(value.transportSettings.additionalData)
                        this.transportSettingsData.transportSettings.additionalData = additionalDataStringify
                    }
                    this.$v.$touch()
                }
            },
            deep: true,
            immediate: true,
        },
        'transportSettings.supplierId': {
            handler (value) {
                if (value) {
                    if (!this.isAllowedTo('getLocationsBySupplierId')) {
                        return
                    }
                    this.$store.dispatch('suppliers/fetchSupplierLocations', this.transportSettings.supplierId).then((data) => {
                        this.allLocations = data.filter(e => e.locationType === 'FACILITY').map((e) => {
                            return {
                                ...e,
                                label: `${e.locationName} - ${e.address}, ${e.zipCode}${e.addressAdditional} ${e.city}, ${e.country}`,
                            }
                        })
                    })
                }
            },
            immediate: true,
        },
        'transportSettings.transportSettings.transportFormat' (value) {
            if (value === 'Custom\\ClassName') {
                this.$nextTick(() => {
                    try {
                        this.$refs.customTransportFormat[0].$el.select()
                        this.$refs.customTransportFormat[0].$el.focus()
                    } catch (exception) {

                    }
                })
            }
        },
    },
    methods: {
        getFieldName (field) {
            return field.toString().replace(/^((otherT|t)ransport)/gm, '')
        },
        submit () {
            let url
            if (this.formType === 'edit') {
                if (!this.isAllowedTo('updateSupplierTransportSetting')) {
                    return
                }
                url = CONFIG.API.ROUTES.ORDER_SUBMISSION.TRANSPORT_SETTINGS.UPDATE.replace('{transportSettingId}', this.$route.params.transportSettingId)
            } else {
                if (!this.isAllowedTo('addSupplierTransportSetting')) {
                    return
                }
                url = CONFIG.API.ROUTES.ORDER_SUBMISSION.TRANSPORT_SETTINGS.ADD
            }
            this.loading = true
            const jobName = JSON.stringify(this.localConfiguration)

            this.addJob(jobName)

            const payload = JSON.parse(JSON.stringify(this.transportSettings))

            if (payload.transportSettings.transportClass === 'other') {
                payload.transportSettings.transportClass = payload.transportSettings.otherTransportClass
            }
            if (payload.transportSettings.transportClass === 'Specific\\PubSubTransport') {
                payload.transportSettings.transportFormat = 'Specific\\Json\\PubSubFormatter'
            } else if (!payload.transportSettings.transportClass.includes('Http') && payload.transportSettings.transportClass !== 'Specific\\PubSubTransport') {
                payload.transportSettings.transportFormat = null
            }
            payload.callbackSettings.callbackSettings = JSON.parse(JSON.stringify(payload.callbackSettings.callbackSettings))
            payload.transportSettings.additionalData = JSON.parse(JSON.stringify(payload.transportSettings.additionalData))

            this.$api.post(url, payload).then(() => {
                this.$root.$emit('notification:global', {
                    message: `Saving transport settings completed.`,
                })
                this.$router.push({
                    name: 'OrderSubmissionsTransportSettingsView',
                })
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Saving transport settings failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.loading = false
                this.finishJob(jobName)
            })
        },
    },
    created () {
        const params = JSON.parse(JSON.stringify(CONFIG.API.REQUEST_DEFAULT))
        params.request.sortModel.push({
            colId: 'supplierName',
            sort: 'asc',
        })
        if (!this.isAllowedTo('countOrdersBySupplierAndStatus') || !this.isAllowedTo('getSuppliersByParentId')) {
            return
        }
        const url = CONFIG.API.ROUTES.SUPPLIERS.OVERVIEW
        this.addJob(url)
        this.$api.post(url, params).then(({ data }) => {
            this.$set(this, 'allSuppliers', data.data.items)
        }).finally(() => {
            this.finishJob(url)
        })
    },
}
</script>
